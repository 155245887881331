import React from 'react';
import './Products.css';

export default function Products() {
    return (
        <div className="catalog">
            <span className="pricingTitle">Our Pricing</span>
            <div className="productRates">
                <div className="newStitch">
                    <div className="product">
                        <div className="category">Blouse</div>
                        <div className="productList">
                            <div className="productITem">
                                <div className="productName">Normal Blouse</div>
                                <div className="productPrice">&#8377;199</div>
                            </div>
                            <div className="productITem">
                                <div className="productName">Lining Blouse(without lining cloth)</div>
                                <div className="productPrice">&#8377;349</div>
                            </div>
                            <div className="productITem">
                                <div className="productName">Lining Blouse(with lining cloth)</div>
                                <div className="productPrice">&#8377;399</div>
                            </div>
                            <div className="productITem">
                                <div className="productName">Basic Designer blouse</div>
                                <div className="productPrice">&#8377;299 onwards</div>
                            </div>
                        </div>
                    </div>
                    <div className="product">
                        <div className="category">Salwar</div>
                        <div className="productList">
                            <div className="productITem">
                                <div className="productName">Normal Salwar</div>
                                <div className="productPrice">&#8377;349</div>
                            </div>
                            <div className="productITem">
                                <div className="productName">Lining Salwar(without lining cloth)</div>
                                <div className="productPrice">&#8377;449</div>
                            </div>
                            <div className="productITem">
                                <div className="productName">Lining Salwar(with lining cloth)</div>
                                <div className="productPrice">&#8377;549</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="alter">
                    <div className="product">
                        <div className="category">Alterations</div>
                        <div className="productList">
                            <div className="productITem">
                                <div className="productName">Over Stitch</div>
                                <div className="productPrice">&#8377;49</div>
                            </div>
                            <div className="productITem">
                                <div className="productName">Hand Attachment</div>
                                <div className="productPrice">&#8377;49</div>
                            </div>
                            <div className="productITem">
                                <div className="productName">Saree Kunjam(Normal design)</div>
                                <div className="productPrice">&#8377;59</div>
                            </div>
                            <div className="productITem">
                                <div className="productName">Size Correction</div>
                                <div className="productPrice">&#8377;59</div>
                            </div>
                            <div className="productITem">
                                <div className="productName">Saree Falls attachment</div>
                                <div className="productPrice">&#8377;89</div>
                            </div>
                            <div className="productITem">
                                <div className="productName">Rope attachment</div>
                                <div className="productPrice">&#8377;39(Per rope)</div>
                            </div>
                            <div className="productITem">
                                <div className="productName">Extra beads attachment</div>
                                <div className="productPrice">&#8377;119(without beads)</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="note">
                <ul>
                    <li>Design book will be brought by pickup agent to choose the neck designs.</li>
                    <li>Free pickup and delivery is available for minimum order for &#8377; 199. </li>
                </ul>
            </div>
        </div>
    )
}
