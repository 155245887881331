import banner1 from '../static/banner1.svg';
import banner2 from '../static/banner2.svg';
import banner3 from '../static/banner3.svg';
import banner4 from '../static/banner4.svg';


const slideData = [
    {
      img_id : 'b_1',
      img: banner1,
    },
    {
      img_id : 'b_2',
      img: banner2,
    },
    {
      img_id : 'b_3',
      img: banner3,
    },
    {
      img_id : 'b_4',
      img: banner4,
    },
  ];
  
  export default slideData;