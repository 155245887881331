import React, { useState, useRef, useEffect } from 'react';
import './Banner.css';
import slideData from '../data/bannerImageData';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

export default function Banner() {
    const [current, setCurrent] = useState(0);
    const slength = slideData.length
    // For automatic slide 
    const delay = 4000;
    const timeoutRef = useRef(null);
    useEffect(() => {
        const resetTimeout = () => {
            if (timeoutRef.current) {
              clearTimeout(timeoutRef.current);
            }
        }
        resetTimeout();
        timeoutRef.current = setTimeout(
          () =>
          setCurrent((prevIndex) =>
              prevIndex === slength - 1 ? 0 : prevIndex + 1
            ),
          delay
        );
        return () => {
          resetTimeout();
        };
    }, [current, slength]);
    // For automatic slide
    const nextSlide = () => {
        setCurrent(current === slength -1 ? 0 : current + 1)
    }
    const prevSlide = () => {
        setCurrent(current === 0 ? slength -1 : current - 1)
    }
    if(!Array.isArray(slideData) || slength <= 0) {
        return null;
    }

    return (
        <div className="homeBanner">
            <div className="bannerContent">
                <h2 className="textA">
                    Home Tailors brought to your home
                </h2>
                <div className="numberSec">
                    <h2 className="numberTxt">
                        Dial <span className="mobileNumber">9344582862</span> for placing order
                    </h2>
                </div>
                
            </div>
            <div className="carousel">
                {slideData.map((slide, index)=>{
                    return (
                        <div
                            className={index === current ? 'slide active' : 'slide'}
                            key={index}
                        >
                            {index === current && (
                                <img src={slide.img} alt="img" className="image"/>
                            )}
                        </div>    
                    )
                })}
                <div className="arrows">
                    <div className="leftArrow">
                        <ArrowBackIosIcon onClick={prevSlide}/>
                    </div>
                    <div className="rightArrow">
                        <ArrowForwardIosIcon onClick={nextSlide}/>
                    </div>
                </div>
                
            </div>
        </div>
    );
}