import React from 'react';
import './Principles.css';
import tailor from '../static/tailor.svg';
import okay from '../static/ok.svg';
import tape from '../static/tape.svg';

export default function Principles() {
    return (
        <div className="principleArea">
            <div className="principleContent">
                <div className="pimage">
                    <img src={tailor} alt="img"/>
                </div>
                <div className="pheading">
                    <h1>1 time Measurement</h1>
                </div>
                <div className="ptext">
                    <p>Save measurements and order blouse of your choice!</p>
                </div>
            </div>
            <div className="principleContent">
                <div className="pimage">
                    <img src={okay} alt="img"/>
                </div>
                <div className="pheading">
                    <h1>Perfect Fit</h1>
                </div>
                <div className="ptext">
                    <p>Perfect match with your sample dress!</p>
                </div>
            </div>
            <div className="principleContent">
                <div className="pimage">
                    <img src={tape} alt="img"/>
                </div>
                <div className="pheading">
                    <h1>Easy Alteration</h1>
                </div>
                <div className="ptext">
                    <p>We can alter your newly stitched dress  as per your need!</p>
                </div>
            </div>
        </div>
    )
}
