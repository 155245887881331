import neck1 from '../static/neck1.jpg';
import neck2 from '../static/neck2.jpg';
import neck3 from '../static/neck3.jpg';
import neck4 from '../static/neck4.jpg';
import neck5 from '../static/neck5.jpg';
import neck6 from '../static/neck6.jpg';
import neck7 from '../static/neck7.jpg';
import neck8 from '../static/neck8.jpg';
import neck9 from '../static/neck9.jpg';
import neck10 from '../static/neck10.jpg';
import neck11 from '../static/neck11.jpg';
import neck12 from '../static/neck12.jpg';

const itemData = [
    {
      img_id : 'n_1',
      img: neck1,
    },
    {
      img_id : 'n_2',
      img: neck2,
    },
    {
      img_id : 'n_3',
      img: neck3,
    },
    {
      img_id : 'n_4',
      img: neck4,
    },
    {
      img_id : 'n_5',
      img: neck5,
    },
    {
      img_id : 'n_6',
      img: neck6,
    },
    {
      img_id : 'n_7',
      img: neck7,
    },
    {
      img_id : 'n_8',
      img: neck8,
    },
    {
      img_id : 'n_9',
      img: neck9,
    },
    {
      img_id : 'n_10',
      img: neck10,
    },
    {
      img_id : 'n_11',
      img: neck11,
    },
    {
      img_id : 'n_12',
      img: neck12,
    },
  ];
  
  export default itemData;