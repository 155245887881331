import React from 'react'
import './Footer.css';

export default function Footer() {
    return (
        <div className="footer">
            <div className="horizLine"></div>
            <div className="footerContent">
                <div className="firstRow">
                    <div className="address">
                        <h2>Main Address :</h2>
                        <p>No.C-2, Swaminathan street, Ekkattuthangal, <br/>Guindy, Chennai-600032</p>
                    </div>
                    <div className="contact">
                        <h4>Contact us during our working hours<br/>(Mon - Sat, 9 A.M - 6 P.M)</h4>
                        <div className="contactNumber">
                            9344582862
                        </div>
                    </div>
                </div>
                <div className="secondRow">
                    <ul className="footer-links">
                        <li><a href='/#'>© urTailor 2019. All Rights Reserved.</a></li>
                    </ul>

                </div>
            </div>
        </div>
    )
}
