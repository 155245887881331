import React, {useState} from 'react';
import './Delivery.css';
import TextField from '@material-ui/core/TextField';
import delivery from '../static/delivery.jpg'

export default function Delivery() {
    const CHARACTER_LIMIT = 6;
    const [pincode, setPincode] = useState(0);
    var pincodes = ['600032', '600031'];
    return (
        <div className="deliveryCheck">
            <div className="deliveryImage">
                <img className="deliImg" src={delivery} alt="img"/>
            </div>
            <div className="deliveryTextArea">
                <div className="deliveryText">
                    Check whether your pincode is eligible for 48 hours delivery
                </div>
                <TextField
                    id="outlined-password-input"
                    label="PINCODE"
                    variant="outlined"
                    inputProps={{
                        maxLength: CHARACTER_LIMIT
                    }}
                    onChange={e => setPincode(e.target.value)}
                />
                {pincode.length === 6  ?  (
                    pincodes.includes(pincode) ? ( 
                        <p className="deliveryResult">Hurray! Your pincode is eligible</p>
                    ) : [(
                        <p className="deliveryResultNA" key="r1">Your pincode is not eligible currently! We will add it shortly!</p>
                    )]
                ): [(
                    <p key="r2"></p>
                )]}
                
            </div>
        </div>
    )
}
