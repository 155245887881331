const neckData = [
    {
        q_id: 1,
        question: 'What is urTailor?',
        answer: 'We are actually a group of tailors trained and guided by experienced tailors who have 20-30 years of experience in tailoring industry.'
    },
    {
        q_id: 2,
        question: 'How urTailor works?',
        answer: 'Our agents pickup your material and sample dress from your location, get stitched by tailors, undergo vigorous stitching quality check and deliver to your location at the mentioned date and time.'
    },
    {
        q_id: 3,
        question: 'Does alteration done on newly stitched dress?',
        answer: 'We take utmost steps to stitch dress with your sample dress. If in case the measurements mismatch we offer one time free alteration. just call and book appointment.'
    },
    {
        q_id: 4,
        question: 'What is urNumber and how it works?',
        answer: 'urNumber is a unique system provided by urTailor, its nothing but your registered mobile number. At the very first time when you place an order, we store your measurements and you can order with that same measurements to deliver the blouse without the pickup.'
    },
    {
        q_id: 5,
        question: 'What is 48H?',
        answer: ' Orders placing with 48H option will be picked and delivered within 48 hours for selected pincodes, we are expanding soon to other pincodes.Orders delivered a second delay after 48 hours will be free of cost.'
    },
    {
        q_id: 6,
        question: 'Does alteration has pickup and delivery service?',
        answer: 'Yes, order value of more than \u20B9199 is eligible for free pickup and delivery service. Order value less than \u20B9199 will incur \u20B949 charge for pickup and delivery.'
    },
]

export default neckData;