import React from 'react';
import './Process.css';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import faqData from '../data/faqData';


export default function Process() {
    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

  return (
    <div className="FAQs">
        <h1>FAQs</h1>
        <div className="questions">
            {faqData.map((item) => (
                <Accordion expanded={expanded === item.q_id} onChange={handleChange(item.q_id)} key={item.q_id}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography className="title">{item.question}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>{item.answer}</Typography>
                </AccordionDetails>
                </Accordion>
            ))}
        </div>
    </div>
  );
}
