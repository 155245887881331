import './App.css';
import logo from './static/logo.png'
import Banner from './components/Banner.js';
import Principles from './components/Principles.js';
import Delivery from './components/Delivery.js';
import Products from './components/Products.js';
import Process from './components/Process.js';
import Designs from './components/NeckDesigns.js';
import Footer from './components/Footer';

function App() {
  return (
    <div className="App">
      <div className="navbar">
        <a href='/' className="logo"><img src={logo} alt="urTailor" /></a>
        <ul className="nav-links">
            <li><a href="#productsNav" >Pricing</a></li>
            <li><a href="#deliveryNav" >Delivery</a></li>
            <li><a href="#FAQNav" >FAQs</a></li>
        </ul>
      </div>
      <div id="bannerNAV">
        <Banner />
        <Principles />
      </div>
      <div id="deliveryNav"><Delivery /></div>
      <div id="productsNav"><Products /></div>
      <Designs />
      <div id="FAQNav"><Process /></div>
      <Footer />
    </div>
  );
}

export default App;
