import React from 'react';
import './NeckDesigns.css';
import itemData from '../data/neckData';


export default function NeckDesigns() {

    return (
    <div className="popNeckDesigns">
        <h1>urTailor's popular designs</h1>
        <div className="neckDesign">
            {itemData.map((item) => (
                <img src={item.img} alt="img" key={item.img_id}/>
            ))}
        </div>
        <h3>Keep watching this space, we update the designs every week!</h3>
    </div>
);
}